import { mapActions, mapGetters } from "vuex"
import ThemisInput from "@/components/shared/input"
import ThemisDecision from "@/components/shared/decision"
import {
  ARRAY,
  FILE_NAME,
  TRANSLATION_UPLOAD_STATUS,
  TRANSLATION_FILE_UPLOAD_TYPE,
  TRANSLATION_FILE_SIZE_LIMIT_IN_BYTES,
  LINK_DISPLAY_NAME_TRANSLATION_FILE_UPLOAD_POLL_INTERVAL
} from "@/constants"

export default {
  name      : "LinkDisplayNameSidePanel",
  components: {
    ThemisInput,
    ThemisDecision
  },
  data() {
    return {
      showDisableTranslationDialog: false,
      localChannel                : undefined
    }
  },
  methods: {
    ...mapActions({
      notify                                : "shared/notify",
      loadChannels                          : "channels/loadChannels",
      updateChannel                         : "channels/updateChannel",
      downloadLinkDisplayNameTranslationFile: "channels/downloadLinkDisplayNameTranslationFile",
      uploadLinkDisplayNameTranslationFile  : "channels/uploadLinkDisplayNameTranslationFile"
    }),
    handleTranslationChange() {
      if (this.isTranslationEnabled) {
        this.showDisableTranslationDialog = true
      } else {
        this.updateChannel({
          id                              : this.localChannel.id,
          allowLinkDisplayNameTranslations: this.localChannel.allowLinkDisplayNameTranslations
        })
      }
    },
    resetTranslationChange() {
      this.showDisableTranslationDialog                  = false
      this.localChannel.allowLinkDisplayNameTranslations = this.channel.allowLinkDisplayNameTranslations
    },
    openFileExplorerToSelectDocument() {
      this.$refs.input_upload_translation_document.click()
    },
    onDocumentSelection(event) {
      const file = event.target.files[ARRAY.FIRST]
      if (file.size < TRANSLATION_FILE_SIZE_LIMIT_IN_BYTES) {
        this.uploadLinkDisplayNameTranslationFile({ channel: this.channel, file: file })
      }
    },
    clearTranslationFileUploadStatusPollingInterval() {
      if (this.translationFileUploadStatusPolling) {
        clearInterval(this.translationFileUploadStatusPolling)
        this.translationFileUploadStatusPolling = null
      }
    },
    handleDownloadLinkDisplayNameTranslations() {
      this.downloadLinkDisplayNameTranslationFile({
        id  : this.localChannel.id,
        name: FILE_NAME.CHANNEL_LINK_DISPLAY_NAME_TRANSLATIONS(this.localChannel.name)
      })
    }
  },
  computed: {
    ...mapGetters({
      channels                                 : "channels/channels",
      isUpdatingLinkDisplayNameTranslations    : "channels/isUpdatingLinkDisplayNameTranslations",
      isLinkDisplayNameTranslationsUpdated     : "channels/isLinkDisplayNameTranslationsUpdated",
      isDownloadingLinkDisplayNameTranslation  : "channels/isDownloadingLinkDisplayNameTranslation",
      isUploadingLinkDisplayNameTranslationFile: "channels/isUploadingLinkDisplayNameTranslationFile",
      isLinkDisplayNameTranslationFileUploaded : "channels/isLinkDisplayNameTranslationFileUploaded"
    }),
    channel() {
      return this.channels.find(channels => channels.id === +this.$route.params.id)
    },
    isTranslationEnabled() {
      return this.channel.allowLinkDisplayNameTranslations
    },
    allowedTranslationDocumentType() {
      return Object.values(TRANSLATION_FILE_UPLOAD_TYPE).toString()
    },
    linkDisplayNameTranslationUploadStatus() {
      return this.channel?.linkDisplayNameTranslationUploadStatus
    },
    isLinkDisplayNameTranslationUploadInitiated() {
      return this.linkDisplayNameTranslationUploadStatus === TRANSLATION_UPLOAD_STATUS.INITIATED
    },
    isLinkDisplayNameTranslationUploadFailed() {
      return this.linkDisplayNameTranslationUploadStatus === TRANSLATION_UPLOAD_STATUS.FAILURE
    },
    isLinkDisplayNameTranslationUploadSuccess() {
      return this.linkDisplayNameTranslationUploadStatus === TRANSLATION_UPLOAD_STATUS.SUCCESS
    }
  },
  watch: {
    channel: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.localChannel) {
          this.localChannel = { ...newValue }
        }
      }
    },
    isLinkDisplayNameTranslationsUpdated: {
      handler: function(value) {
        if (value) {
          if (!this.isTranslationEnabled) {
            this.notify({
              type: "success",
              text: "2469"
            })
            this.showDisableTranslationDialog = false
          }
        }
      }
    },
    isTranslatingLinkDisplayName: {
      handler: function(newValue) {
        this.$DECISIONS.DISABLE_LINK_DISPLAY_NAME_TRANSLATION.pActions[0].buttonProps.disabled = newValue
        this.$DECISIONS.DISABLE_LINK_DISPLAY_NAME_TRANSLATION.pActions[1].buttonProps.loading  = newValue
      }
    },
    linkDisplayNameTranslationUploadStatus: {
      immediate: true,
      handler  : function(newValue, oldValue) {
        if (newValue === TRANSLATION_UPLOAD_STATUS.INITIATED) {
          this.translationFileUploadStatusPolling = setInterval(() => {
            this.loadChannels({ id: this.channel.id, uploadStatus: newValue })
          }, LINK_DISPLAY_NAME_TRANSLATION_FILE_UPLOAD_POLL_INTERVAL)
        }
        if (newValue === TRANSLATION_UPLOAD_STATUS.SUCCESS || newValue === TRANSLATION_UPLOAD_STATUS.FAILURE) {
          this.clearTranslationFileUploadStatusPollingInterval()
        }
        if (newValue === TRANSLATION_UPLOAD_STATUS.SUCCESS && oldValue === TRANSLATION_UPLOAD_STATUS.INITIATED) {
          this.notify({
            type: "success",
            text: "2494"
          })
        }
      }
    }
  }
}